/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 114번째 로그에서는 개발자 연봉 인상, AWS S3 15주년, Go 개발자 설문, git plan에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "git plan"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/synek/git-plan"
  }, "git-plan - GitHub")), "\n"), "\n", React.createElement(_components.h3, null, "AWS S3 15주년"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/korea/amazon-s3s-15th-birthday-it-is-still-day-1-after-5475-days-100-trillion-objects/"
  }, "Amazon S3 탄생 15 주년 – 5,475일 및 100 조+ 개체 저장~ 여전히 Day 1 입니다! - Amazon Web Services 한국 블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/storage/architecting-for-high-availability-on-amazon-s3/"
  }, "Architecting for high availability on Amazon S3 - AWS Storage Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gist.github.com/electrum/814835"
  }, "Amazon S3 Design Principles - GitHub Gist")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://book.naver.com/bookdb/book_detail.nhn?bid=18004399"
  }, "제프 베조스, 발명과 방황 - 네이버 책")), "\n"), "\n", React.createElement(_components.h3, null, "Go 개발자 설문"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.golang.org/survey2020-results"
  }, "Go Developer Survey 2020 Results - The Go Blog")), "\n"), "\n", React.createElement(_components.h3, null, "개발자 연봉 인상"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mk.co.kr/news/it/view/2021/03/240117/"
  }, "\"보너스로 BMW 뽑았죠\"…연봉 인상 줄잇는 판교는 딴세상 - 매일경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hankyung.com/it/article/202103166808g"
  }, "\"5년차에 연봉 1억 수두룩\"…판교는 지금 딴세상 - 한국경제")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.robertwalters.co.kr/salarysurvey.html"
  }, "2021 디지털 연봉 조사서 - 로버트 월터스")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
